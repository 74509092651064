import React from "react"
import { css, Heading } from "theme-ui"

export {
  SectionHeading,
  SectionImage,
  SectionParagraph,
  SectionSubHeading,
  SectionImageCaption,
}

const SectionHeading = ({ ...props }) => (
  <Heading
    {...props}
    css={css({ fontWeight: 400, paddingTop: 10 })}
  />
)

const SectionImage = ({ ...props }) => (
  <img
    {...props}
    css={css({ maxWidth: '100%', display: 'inline', textAlign: 'center' })}
  />
)

const SectionImageCaption = ({ ...props }) => (
  <div><small 
    {...props}
    css={css({ fontWeight: 300 })}
  /></div>
)

const SectionParagraph = ({ ...props }) => (
  <div
    {...props}
    css={css({ fontWeight: 300, paddingTop: 1 })}
  />
)

const SectionSubHeading = ({ ...props }) => (
  <Heading as='h3'
    {...props}
    css={css({ fontWeight: 400, paddingTop: 3 })}
  />
)