import React from "react"
import { css, useThemeUI } from "theme-ui"
import SiteHeader from "../../components/siteHeader"

export default function Header() {
  // Set the container size
  const context = useThemeUI()
  // console.log('context', context)
  context.theme.sizes.container = 1024;
  
  return (
    <header>
      <div>
        <SiteHeader />
      </div>
    </header>
  )
}